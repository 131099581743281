a, a:hover,
.btn-link, .btn-link:hover,
.card {
  color: #212529;
}

footer {
  background-color: #252525;
}

  footer, footer a, footer a:hover {
    color: gainsboro;
  }

h3 {
  color: #D29FA8;
}

ol>li {
  color: #D29FA8;
}

  ol>li>span {
    color: #212529;
  }

.btn-primary {
  background-color: #e49aa7;
  border-color: #e49aa7;
  color: white;
}

  .btn-primary:hover {
    background-color: #df8695;
    border-color: #da7283;
  }
  
  .btn-primary:not(:disabled):not(.disabled).active, 
  .btn-primary:not(:disabled):not(.disabled):active, 
  .show>.btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #dd677a;
      border-color: #d8586d;
  }

.card-img {
  background-color: white;
}

.card-img-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

  .card-img-overlay, .card-img-overlay a {
    color: white;
  }

.dropzone>.input-container {
  border-color: #212529;
}

.dropzone.highlight>.input-container {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.dropzone.with-image:hover>img {
  opacity: .5;
}

.dropzone.with-image:not(:hover)>.input-container {
  opacity: .5;
}

.icon.green {
  color: #4CAE51;
}

.input-group.gender>:first-child>button.is-invalid,
.was-validated .input-group.gender>:first-child>button:invalid {
  border-top-color: #dc3545;
  border-left-color: #dc3545;
  border-bottom-color: #dc3545;
}

.input-group.gender>:not(:first-child):not(:last-child)>button.is-invalid,
.was-validated .input-group.gender>:not(:first-child):not(:last-child)>button:invalid {
  border-top-color: #dc3545;
  border-bottom-color: #dc3545;
}

.line-separator {
  background:#717171;  
  border-bottom:1px solid #313030;
}

.list-group-item {
  background-color: transparent;
  border: none;
}

.panel-with-image,
.panel-with-image a, 
.panel-with-image a:hover, 
.panel-with-image .btn-link, 
.panel-with-image .btn-link:hover {
  color: white;
}

  .panel-with-image>* {
    background-color: rgba(0, 0, 0, .5);
  }

  .panel-with-image.deep>* {
    background-color: rgba(0, 0, 0, .75);
  }

.picture.about {
  border-color: gainsboro;
}

.tooltip-error>.tooltip-inner {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.tooltip-error.bs-tooltip-top .arrow::before {
  border-top-color: #dc3545;
}

.tooltip-error.bs-tooltip-right .arrow::before {
  border-right-color: #dc3545;
}

.tooltip-error.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #dc3545;
}

.tooltip-error.bs-tooltip-left .arrow::before {
  border-left-color: #dc3545;
}