body {
  font-family: "Open Sans", sans-serif;
  text-align: left;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1 {
  font-size: 2rem; 
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4, h5 {
  font-size: 1rem;
}

ol>li {
  font-weight: 700;
}

  ol>li>span {
    font-weight: 400;
  }

small {
  font-size: 90%;
}

.card {
  font-size: .95rem;
}

.card-img-overlay a:hover {
  text-decoration: none;
}

.form-text, 
.tooltip-error p, 
.tooltip-error li, 
.tooltip-error ul {
  text-align: left;
}

.navbar-brand { 
  font-size: 1.78rem;
  font-weight: 500;
}

.recipes h2 {
  font-size: 1rem;
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}
