body {
  margin: 0;
  padding: 0;
  position: relative;
}

footer {
  bottom: 0;
  padding: 16px 0 38px;
  position: absolute;
  width: 100%;
}
  
  footer .nav-links {
    float: left;
  }

  footer .social-network-links {
    float: right;
  }

    footer .social-network-links .list-group-item {
      padding: 0 0 0 1em;
    }

main {
  min-height: 100vh;
  padding-top: 70px;
}

li {
  margin-bottom: 0.5em;
}

ul {
  padding-inline-start: 0;
}

  ul>li {
    list-style-type: none;
  }

.recipe-info:not(:last-child) {
  margin-bottom: 1rem;
 } 

  .recipe-info>*:not(:last-child) {
    margin-bottom: 1rem;
  }

  .recipe-info .info {
    padding-right: 1rem;
  }

  .recipe-info .icon.icon-sm {
    margin-bottom: .2rem;
  }
  
.about.picture {
  float: left;
  margin: 16px;
  border: 2px solid;
}

.btn-group .icon {
  margin: 0 .5rem .2rem 0;
}

.card {
  width: 18rem;
  min-width: 18rem;
}

  .card p {
    margin-bottom: .5rem;
  }

  .card:first-child:not(:last-child) .card-img {
    border-top-right-radius: 0;
  }

  .card:not(:first-child):not(:last-child) .card-img {
    border-radius: 0;
  }

  .card:not(:first-child):last-child .card-img {
    border-top-left-radius: 0;
  }

  .card .icon {
    margin: .1rem;
    cursor: pointer; 
    float: right; 
  }

.card.add {
  width: 8.5rem;
  min-width: 8.5rem;
}

  .card.add>.card-img {
    position: relative;
  }

  .card.add .icon {
    left: 3.25rem;
    position: absolute;
    top: 3.25rem;
  }

.card-body {
  padding: 5px;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
}

.card-list:not(:last-child) {
  margin: 0 0 .5rem;
}

  .card-list .card-group {
    flex-flow: row;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  
  .card-list .card-group::-webkit-scrollbar {
    display: none;
  }

.card-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 8.5rem;
  object-fit: cover;
}

.card-img.default-image {
  object-fit: contain;
}

.card-img-overlay {
  padding: 5px 16px;
}

.card-title {
  margin: 0;
}

.container.lists {
  padding-right: 0;
}

  .container.lists .row {
    margin-right: 0;
  }

    .container.lists .row>div {
      padding-right: 0;
    }

.dropzone {
  cursor: pointer;
  margin-bottom: 16px;
}

  .dropzone>img {
    width: 100%;
  }

  .dropzone>.input-container {
    align-items: center;
    border: 2px dashed;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  
  .dropzone:not(.with-image)>.input-container {
    min-height: 200px;
  }

    .dropzone>.input-container>input {
      display: none;
    }

.form-buttons {
  float: right;
}

.form-control.password.is-invalid, 
.was-validated .form-control.password:invalid {
  margin-right: 1px;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.icon.icon-sm {
  height: 1.25rem;
  width: 1.25rem;
}

.icon.icon-lg {
  height: 2rem;
  width: 2rem;
}

.icon.linkedin {
  height: 1.2rem;
}

.image-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-container.default-image {
  background-size: contain;
}

.line-separator {
  height:1px;
  margin-bottom: 8px;
}

.list-group-item {
  padding: 0 1em 0 0;
}

.margin-bottom-sm {
  margin-bottom: .5rem;
}

.navbar {
  height: 70px;
  padding: 0 4vw;
}

.navbar-brand { 
  display: inline-block;
  margin: 0;
  padding: 0;
}

.panel {
  padding-top: 16px;
  padding-bottom: 16px;
}

.panel-with-image {
  align-items: center;
  display: flex;
  padding: 75px 0;
}

  .panel-with-image>* {
    padding: 16px 0;
    width: 100%;
  }

  .panel-with-image form {
    margin-top: .4em;
  }

.panel-with-image.no-padding {
  padding: 0;
}

  .panel-with-image.no-padding>* {
    padding: 56px 0;
  }

.picture.small {
  width: 6.25rem; 
}

.picture.medium {
  width: 10rem; 
}

.picture.large {
  width: 15rem;
}

.recipes {
  margin-left: 4vw;
}

  .recipes h2 {
    margin-bottom: .25em;
  }

.recipe-picture {
  height: 100%;
  padding-bottom: 56.25%;
}

.recipe-view .panel-with-image .col-lg-6 {
  margin: 8px 0;
}

.text-align-center .list-group-item {
  padding: 0 .5em;
}

.tooltip-error p, .tooltip-error li, .tooltip-error ul {
  margin: 0;
}