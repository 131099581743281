body {
  font-family: "Open Sans", sans-serif;
  text-align: left;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1 {
  font-size: 2rem; 
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h4, h5 {
  font-size: 1rem;
}

ol>li {
  font-weight: 700;
}

  ol>li>span {
    font-weight: 400;
  }

small {
  font-size: 90%;
}

.card {
  font-size: .95rem;
}

.card-img-overlay a:hover {
  text-decoration: none;
}

.form-text, 
.tooltip-error p, 
.tooltip-error li, 
.tooltip-error ul {
  text-align: left;
}

.navbar-brand { 
  font-size: 1.78rem;
  font-weight: 500;
}

.recipes h2 {
  font-size: 1rem;
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

footer {
  bottom: 0;
  padding: 16px 0 38px;
  position: absolute;
  width: 100%;
}
  
  footer .nav-links {
    float: left;
  }

  footer .social-network-links {
    float: right;
  }

    footer .social-network-links .list-group-item {
      padding: 0 0 0 1em;
    }

main {
  min-height: 100vh;
  padding-top: 70px;
}

li {
  margin-bottom: 0.5em;
}

ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

  ul>li {
    list-style-type: none;
  }

.recipe-info:not(:last-child) {
  margin-bottom: 1rem;
 } 

  .recipe-info>*:not(:last-child) {
    margin-bottom: 1rem;
  }

  .recipe-info .info {
    padding-right: 1rem;
  }

  .recipe-info .icon.icon-sm {
    margin-bottom: .2rem;
  }
  
.about.picture {
  float: left;
  margin: 16px;
  border: 2px solid;
}

.btn-group .icon {
  margin: 0 .5rem .2rem 0;
}

.card {
  width: 18rem;
  min-width: 18rem;
}

  .card p {
    margin-bottom: .5rem;
  }

  .card:first-child:not(:last-child) .card-img {
    border-top-right-radius: 0;
  }

  .card:not(:first-child):not(:last-child) .card-img {
    border-radius: 0;
  }

  .card:not(:first-child):last-child .card-img {
    border-top-left-radius: 0;
  }

  .card .icon {
    margin: .1rem;
    cursor: pointer; 
    float: right; 
  }

.card.add {
  width: 8.5rem;
  min-width: 8.5rem;
}

  .card.add>.card-img {
    position: relative;
  }

  .card.add .icon {
    left: 3.25rem;
    position: absolute;
    top: 3.25rem;
  }

.card-body {
  padding: 5px;
}

.card-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.card-list:not(:last-child) {
  margin: 0 0 .5rem;
}

  .card-list .card-group {
    -webkit-flex-flow: row;
            flex-flow: row;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  
  .card-list .card-group::-webkit-scrollbar {
    display: none;
  }

.card-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 8.5rem;
  object-fit: cover;
}

.card-img.default-image {
  object-fit: contain;
}

.card-img-overlay {
  padding: 5px 16px;
}

.card-title {
  margin: 0;
}

.container.lists {
  padding-right: 0;
}

  .container.lists .row {
    margin-right: 0;
  }

    .container.lists .row>div {
      padding-right: 0;
    }

.dropzone {
  cursor: pointer;
  margin-bottom: 16px;
}

  .dropzone>img {
    width: 100%;
  }

  .dropzone>.input-container {
    -webkit-align-items: center;
            align-items: center;
    border: 2px dashed;
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
  }
  
  .dropzone:not(.with-image)>.input-container {
    min-height: 200px;
  }

    .dropzone>.input-container>input {
      display: none;
    }

.form-buttons {
  float: right;
}

.form-control.password.is-invalid, 
.was-validated .form-control.password:invalid {
  margin-right: 1px;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.icon.icon-sm {
  height: 1.25rem;
  width: 1.25rem;
}

.icon.icon-lg {
  height: 2rem;
  width: 2rem;
}

.icon.linkedin {
  height: 1.2rem;
}

.image-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-container.default-image {
  background-size: contain;
}

.line-separator {
  height:1px;
  margin-bottom: 8px;
}

.list-group-item {
  padding: 0 1em 0 0;
}

.margin-bottom-sm {
  margin-bottom: .5rem;
}

.navbar {
  height: 70px;
  padding: 0 4vw;
}

.navbar-brand { 
  display: inline-block;
  margin: 0;
  padding: 0;
}

.panel {
  padding-top: 16px;
  padding-bottom: 16px;
}

.panel-with-image {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  padding: 75px 0;
}

  .panel-with-image>* {
    padding: 16px 0;
    width: 100%;
  }

  .panel-with-image form {
    margin-top: .4em;
  }

.panel-with-image.no-padding {
  padding: 0;
}

  .panel-with-image.no-padding>* {
    padding: 56px 0;
  }

.picture.small {
  width: 6.25rem; 
}

.picture.medium {
  width: 10rem; 
}

.picture.large {
  width: 15rem;
}

.recipes {
  margin-left: 4vw;
}

  .recipes h2 {
    margin-bottom: .25em;
  }

.recipe-picture {
  height: 100%;
  padding-bottom: 56.25%;
}

.recipe-view .panel-with-image .col-lg-6 {
  margin: 8px 0;
}

.text-align-center .list-group-item {
  padding: 0 .5em;
}

.tooltip-error p, .tooltip-error li, .tooltip-error ul {
  margin: 0;
}
a, a:hover,
.btn-link, .btn-link:hover,
.card {
  color: #212529;
}

footer {
  background-color: #252525;
}

  footer, footer a, footer a:hover {
    color: gainsboro;
  }

h3 {
  color: #D29FA8;
}

ol>li {
  color: #D29FA8;
}

  ol>li>span {
    color: #212529;
  }

.btn-primary {
  background-color: #e49aa7;
  border-color: #e49aa7;
  color: white;
}

  .btn-primary:hover {
    background-color: #df8695;
    border-color: #da7283;
  }
  
  .btn-primary:not(:disabled):not(.disabled).active, 
  .btn-primary:not(:disabled):not(.disabled):active, 
  .show>.btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #dd677a;
      border-color: #d8586d;
  }

.card-img {
  background-color: white;
}

.card-img-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

  .card-img-overlay, .card-img-overlay a {
    color: white;
  }

.dropzone>.input-container {
  border-color: #212529;
}

.dropzone.highlight>.input-container {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}

.dropzone.with-image:hover>img {
  opacity: .5;
}

.dropzone.with-image:not(:hover)>.input-container {
  opacity: .5;
}

.icon.green {
  color: #4CAE51;
}

.input-group.gender>:first-child>button.is-invalid,
.was-validated .input-group.gender>:first-child>button:invalid {
  border-top-color: #dc3545;
  border-left-color: #dc3545;
  border-bottom-color: #dc3545;
}

.input-group.gender>:not(:first-child):not(:last-child)>button.is-invalid,
.was-validated .input-group.gender>:not(:first-child):not(:last-child)>button:invalid {
  border-top-color: #dc3545;
  border-bottom-color: #dc3545;
}

.line-separator {
  background:#717171;  
  border-bottom:1px solid #313030;
}

.list-group-item {
  background-color: transparent;
  border: none;
}

.panel-with-image,
.panel-with-image a, 
.panel-with-image a:hover, 
.panel-with-image .btn-link, 
.panel-with-image .btn-link:hover {
  color: white;
}

  .panel-with-image>* {
    background-color: rgba(0, 0, 0, .5);
  }

  .panel-with-image.deep>* {
    background-color: rgba(0, 0, 0, .75);
  }

.picture.about {
  border-color: gainsboro;
}

.tooltip-error>.tooltip-inner {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}

.tooltip-error.bs-tooltip-top .arrow::before {
  border-top-color: #dc3545;
}

.tooltip-error.bs-tooltip-right .arrow::before {
  border-right-color: #dc3545;
}

.tooltip-error.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #dc3545;
}

.tooltip-error.bs-tooltip-left .arrow::before {
  border-left-color: #dc3545;
}
